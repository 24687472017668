import React                from 'react';
import sanitizeHtml         from 'sanitize-html';

/**
 * Component that can render a limited set of sub fields or HTML which is sanitized
 * */
const InfoBox = props => {

    const { subFields = null, name, text = '' } = props;

    let result;
    if (Array.isArray(subFields)) {
        result = <SubFieldInfoBox subFields={subFields} />
    } else {
        result = <HTMLInfoBox text={ text } />
    }
    
    return result;
}

const HTMLInfoBox = ({ text }) => {

    const result = { '__html': sanitizeHtml(text) };

    return <div role='heading' dangerouslySetInnerHTML={ result } />;
}

const SubFieldInfoBox = ({ subFields }) => {

    const render = renderSubFields(subFields);
    
    return React.createElement(
        'div',
        { role: 'heading' },
        render);
} 

const renderSubFields = ( subFields ) => {
    
    let inList = false;
    
    let currentList = [];
    
    const output = [];
    
    subFields.forEach( (subField, index) => {
        
        const { text, href, type }                  = subField;

        const props = { key: index };
        if ('a' === type) {
            props.href = href;
        };
        output.push( React.createElement(type, props, text) );
        
        let typeChange = ( inList && 'li' !== type ) || ( !inList && 'li' === type );
        
        if( typeChange || ( subFields.length - 1 ) === index ){
            if( currentList.length ){
                const comp = React.createElement(
                    inList? 'ul': 'p',
                    null,
                    currentList
                );
                output.push( comp );
                currentList = [];
                inList = false;
            }
        }
    } );
    
    return output;
}

const Elem = ({children}) => {
    
    return children.map( (el, index) => React.creatElement(el.type, [{key: index}], el.text) );
}

export default InfoBox;