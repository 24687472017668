
import React, {useState, useEffect, useCallback}                               from 'react';

/**
 * React component to display a list of API calls made by the API. This is no use
 * in production but very usefull in dev to find problems.
 */

const Li = ({logItem}) => {
    
    const dtStyle = {display: 'block', width: '30%', float: 'left', fontWeight: 'bold', textAlign: 'left', clear: 'left'}
    const ddStyle = {...dtStyle, fontWeight: 'normal', clear: 'right', width: '70%', }
    
    return (
            <li style={{listStyle: 'none',color: 'black', float: 'left', display: 'block', width: '100%', borderBottom: '10px solid white', padding: '10px'}} >
                
                <div style={{float: 'left', width: '40%'}}>
                    <h1>Request</h1>
                    <dl style={{display: 'block', width: '100%',}}>
                        <dt style={dtStyle} >MessageID</dt>
                        <dd style={{...ddStyle,color: 'red'}} >{logItem.requestMessageID}</dd>
                        <dt style={dtStyle} >Endpoint</dt>
                        <dd style={ddStyle}>{logItem.requestEndpoint}</dd>
                        <dt style={dtStyle} >HTTPMethod</dt>
                        <dd style={ddStyle}>{logItem.requestHTTPMethod}</dd>
                        <dt style={dtStyle} >Parameters</dt>
                        <dd style={ddStyle}>{logItem.requestParams}</dd>
                    </dl>
                </div>
                <div style={{float: 'left', width: '60%'}}>
                    <h1>Response</h1>
                    <dl>
                        <dt style={dtStyle}>networkOK</dt>
                        <dd style={ddStyle}>{logItem.networkOK? 'true': 'false'}</dd>
                        <dt style={dtStyle}>apiStatus</dt>
                        <dd style={ddStyle}>{logItem.apiStatus}</dd>
                        <dt style={dtStyle}>callTime</dt>
                        <dd style={ddStyle}>{logItem.callDurationMs} ms</dd>
                        <dt style={dtStyle}>apiErrorText</dt>
                        <dd style={ddStyle}>{logItem.apiText}</dd>
                        <dt style={dtStyle}>apiMetadata</dt>
                        <dd style={ddStyle}>{logItem.apiMetadata}</dd>
                        <dt style={dtStyle}>Result</dt>
                        <dd style={ddStyle}><pre>{logItem.apiResult}</pre></dd>
                    </dl>
                    </div>
            </li>
    )
}


/**
 * 
 * @param {Array} logs an array of results from the API. Some may be errors, others
 * will be real results
 * @returns {undefined}
 */
const APIDebugLog = ({api}) => {
    
    const [apiLog, setAPILog]               = useState([]);

    /* takes api log events and stringifies some parts for display in the 
     * debugging component 
     * UseCallback to we don't create a new function for every render*/
    const appendAPILog = useCallback((logItem) => {

        /* This is purely because a File object won't provide any JSON output 
         * so confert these to standard Objects so the main fields display. */
        let params = logItem.requestParams
        if( params.Files && Array.isArray( params.Files ) && params.Files.length ){
            params.Files = logItem.requestParams.Files.map( item =>  
                // special treatment for File objects
                item instanceof File? { name: item.name, type: item.type, 
                    size: item.size, lastModifiedDate: item.lastModifiedDate }: item
             )
        }

        // TODO refactor this
        const lICopy = {
            requestMessageID:   logItem.requestMessageID,
            requestEndpoint:    logItem.requestEndpoint,
            requestHTTPMethod:  logItem.requestHTTPMethod,
            requestParams:      JSON.stringify( params, null, 2 ),
            networkOK:          logItem.networkOK,
            apiStatus:          logItem.apiStatus,
            apiText:            logItem.apiText,
            apiMetadata:        JSON.stringify( logItem.apiMetadata ),
            apiResult:          JSON.stringify( logItem.apiResult, null, 2 ),
            callDurationMs:     logItem.callDurationMs,
        }

        setAPILog( oldArray => [...oldArray, lICopy] )
    }, []);
    
    // Only do this once
    useEffect(() => {
        // to get notified of all api calls so as to display the debug  log
        api.addLogCallback( appendAPILog );
    },[api, appendAPILog]);
    
     
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
        return (
            <ul style={{backgroundColor: 'lightgray',float: 'left', display: 'block', clear: 'both', width: '100%', padding: 0}}>
                { apiLog.slice().reverse().map( (logItem, index) => <Li key={index} logItem={logItem} /> ) }
            </ul>
        )
    }else{
        return <div></div>
    }
}

export {APIDebugLog};


