/**
 * Redirects to the same location but without URL paramsRedirects to the same location but without URL params
 * @returns {void}
 */
const clearLocationParams = () => {
    
    const loc = window.location;
    
    window.history.replaceState( {}, 'rem ', loc.origin + loc.pathname );
    
}

/**
 * Gets a parameter from the URL by key
 * @param {type} key 
 * @returns {string} The value or null if not present
 */
const getURLSearchParam = key => {
    
    const urlParams = new URLSearchParams( window.location.search )
    
    return urlParams.get( key )
}

/**
 * Remove the speficied key from the URL and direct to the new URL. So
 * calling removeURLSearchParam('t') would redirect https://example/?t=xyx&a=w
 * to https://example/?a=w removing the t param
 * @param {string} key
 * @returns {void}
 */
const removeURLSearchParam = key => {
    
    const loc               = window.location;
    const urlParams         = new URLSearchParams( loc.search )
    
    if ( urlParams.has( key ) ){
        urlParams.delete( key )
    
        const search    = urlParams.toString()
        const querySt   = search? ( '?' + search ): '' ;
        const newURL    = loc.origin + loc.pathname + querySt;
        window.history.replaceState( {}, 'rem ' + key, newURL );
    }
}

const getURLPathArray = () => {

    const path      = window.location.pathname;
    const pathArray = path.split('/');
    return pathArray;
}

export {getURLSearchParam, removeURLSearchParam, getURLPathArray}
export default clearLocationParams


