import React, {useState, useEffect} from 'react';
import phoneImage from './img/phone-image.jpg';
import talkingOnHeadset from './img/talking-on-headset.jpg';
import {LoadingIndicator} from '../../lib/iprs-react-library/src/index';
import PhoneNumber from '../PhoneNumber';
import '../Application/css/styles.css'
import './styles.css';
import './sit2kvo.css';

const Option = ({ api, tokenContent, brandingInfo}) => {

    const token = api?.getToken()?? 'No URL Token';

    const [err, setErr] = useState('');

    const [loading, setLoading] = useState(false);

    // 1. This useEffect runs when token state is changed.
    //    Token is used to make api.transact('ValidateToken', params) request
    //    and received token is decoded.
    //    The decoded token is used to set validatedToken
    const [validatedToken, setValidatedToken] = useState('');
    const [assessmentChoice, setAssessmentChoice] = useState('');
    useEffect(()=>{
        setValidatedToken(tokenContent);
    },[tokenContent])

    // 2. This useEffect runs when assessmentChoice is changed which will happen 
    //    When user clicks one of the choices in the page.
    const [redirectURL, setRedirectURL] = useState('');

    const choiceSelection = (option) => {
        setAssessmentChoice(option);
    };

    useEffect(() => {
        const params = {
            "Token": token,
            'AssessmentType': assessmentChoice
        }
        if (assessmentChoice) {
            setLoading(true);
            api.transact("ConfirmAssessmentType", params)
                .then(response => {
                    setLoading(false);
                    setRedirectURL(response.apiResult.NextURL);
                    setErr('');
                })
                .catch(err => {
                    setLoading(false);
                    setErr(err.message);
                });
        }
    }, [assessmentChoice, api, validatedToken, token]);

    // 3. When redirectURL is known, this useEffect will run to redirect the browser
    useEffect(() => {
        if (redirectURL !== '') {
            window.location.replace(redirectURL);
        }
    }, [redirectURL]);


    // 4. State management for changing class and style of boxes.  
    const [box1Class, setBox1Class] = useState(['feature-box__hover-div', 0]);
    const [box2Class, setBox2Class] = useState(['feature-box__hover-div', 0]);

    const featureBox1 = (event) => {
        event.stopPropagation();
        if (box1Class[1] === 0) {
            setBox1Class(['feature-box__hover-div active', 1])
        } else {
            setBox1Class(['feature-box__hover-div', 0])
        }
    };

    const featureBox2 = (event) => {
        event.stopPropagation();
        if (box2Class[1] === 0) {
            setBox2Class(['feature-box__hover-div active', 1])
        } else {
            setBox2Class(['feature-box__hover-div', 0])
        }
    };

    const options = <>
        <h1>Welcome to IPRS Health Physiotherapy Assessment Portal</h1>
        <p>Please confirm the assessment type that suits you best:</p>
        <div className="feature-boxes" ng-controller="OptionController">
            <div className="feature-box feature-box-1" onClick={() => choiceSelection(1)}>
                <img src={phoneImage} alt="Man looking at mobile phone" />
                <p className="green-button">Start your digital assessment now</p>
                <button className="info__button" onClick={featureBox1}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
                        <g id="Group_401" data-name="infogroup1" transform="translate(-1084 -457)">
                            <circle id="info1" data-name="info1" cx="18.5" cy="18.5" r="18.5" transform="translate(1084 457)" fill="#d75094" />
                            <text id="i" transform="translate(1099 467)" fill="#fff" fontSize="20" fontFamily="neo-sans, sans-serif" fontWeight="700"><tspan x="0" y="15">i</tspan></text>
                        </g>
                    </svg>
                </button>
                <div className={box1Class[0]} style={{ opacity: box1Class[1] }}>
                    <span>
                        Our digital service allows you to instantly complete
                        your assessment with our clinically validated chatbot Phio Access. You can complete the assessment on
                        all mobile and desktop devices 24/7.
                    </span><br />
                    <span>
                        The assessment will take around 15 minutes to complete. At the end of your assessment you will
                        be advised on the most suitable pathway to progress your care. This digital journey is overseen by
                        our experienced team of Physiotherapists.
                    </span>
                    <p className="green-button">Start your digital assessment now</p>
                </div>
            </div>
            <div className="feature-box feature-box-2" onClick={() => choiceSelection(2)}>
                <img src={talkingOnHeadset} alt="Man talking on headset" />
                <p className="green-button">Book your telephone or video clinical assessment</p>
                <button className="info__button" onClick={featureBox2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
                        <g id="Group_401" data-name="infogroup2" transform="translate(-1084 -457)">
                            <circle id="info2" data-name="info2" cx="18.5" cy="18.5" r="18.5" transform="translate(1084 457)" fill="#d75094" />
                            <text id="i" transform="translate(1099 467)" fill="#fff" fontSize="20" fontFamily="neo-sans, sans-serif" fontWeight="700"><tspan x="0" y="15">i</tspan></text>
                        </g>
                    </svg>
                </button>
                <div className={box2Class[0]} style={{ opacity: box2Class[1] }}>
                    <span>
                        Our telephone or video assessments are conducted by experienced physiotherapists.
                        If you select this option you will be taken to an online diary where you can arrange a
                        convenient time to complete the assessment. If you can't find a time to suit you then you can
                        always come back and commence our digital assessment 24/7.
                    </span><br />

                    <span>
                        Telephone and video assessments may take around 30 minutes.
                        At the end of your assessment, our Physiotherapist will discuss the most appropriate
                        treatment plan for you.
                    </span>
                    <p className="green-button">Book your telephone or video clinical assessment</p>
                </div>
            </div>
        </div>

        <p className="bottom-paragraph">Need assistance? Please contact IPRS Health on <PhoneNumber brandingInfo={brandingInfo} />.</p>
    </>;

    return (
        <div className="typography">
            <div className="container">
                <main className="main home-page">
                    {assessmentChoice === '' && tokenContent.Error === undefined ? 
                        options : 
                        <LoadingIndicator loading={loading} />
                    }
                    <div className="optionPageError">
                        <p>
                            {err}
                        </p>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Option