import React, { useState } from "react";
import { useEffect } from "react";
import { getDateISOWithoutTime, isISODate } from "../../../shared/DateUtil";

import { default as Input } from "../../FormInput";

const maxPersonAge = 120;

// internally store as dd/mm/yyyy
const parseDateToObject = dateSt => {
    const [year = "", month = "", day = ""] = dateSt?.split("-") ?? [];

    return { day, month, year };
};

const defaultMaxDaysPrevious = Math.floor(maxPersonAge * 356.25);
const defaultMaxDaysAhead = 0;

const dateAdd = (date, offsetDays) =>
    new Date(date.getTime() + offsetDays * 24 * 60 * 60 * 1000);

const limit = (val, min, max) => {
    const intVal = Number.parseInt(val, 10);

    let result = "";
    if (intVal) {
        result = intVal >= min && intVal <= max ? intVal : "";
    }

    return result;
};

const paddInt = (val, len) => {
    const intVal = Number.parseInt("" + val, 10);
    const result = ("" + intVal).padStart(len, "0");
    return result;
};

const isWhitespace = str => {
    if (typeof str !== "string") {
        return false;
    } else {
        return str.trim() === "";
    }
};

const convertToDate = values => {

    const { day, month, year } = values;
    const dayInt = Number.parseInt(day, 10);
    const monthInt = Number.parseInt(month, 10);
    const yearInt = Number.parseInt(year, 10);

    return new Date(yearInt, monthInt - 1, dayInt);
}

/* get the max date based on the props
complicates as there are defaults and we do DOB differently
as it can never be after today
*/
const getMaxDate = props => {
    const {maxDaysAhead, max, text} = props;

    if (isISODate(max)) {
        return new Date(max);
    }else {
        const maxDaysAheadInt = Number.parseInt(""+maxDaysAhead, 10);
        if(!Number.isNaN(maxDaysAheadInt)){
            return dateAdd(new Date(), maxDaysAheadInt);
        }else{
            if(/birth|dob/i.test(text)){
                return dateAdd(new Date(), defaultMaxDaysAhead);
            }else{
                return new Date(Number.MAX_SAFE_INTEGER);
            }
        }
    } 
}

/*
 * Component to collect a person's date of birth
 * Note this component stores it's state locally and only submits changed values
 * on blur.
 */
const DateTextBox = props => {
    const {
        name,
        text,
        handleChange: parentHandleChange,
        validating: validate,
        required,
        maxDaysAhead = defaultMaxDaysAhead,
        maxDaysPrevious = defaultMaxDaysPrevious,
        pivotYAhead = 0,
        invalidateOnRange = false,
        defaultvalue,
        validationWarning,
        max
    } = props;


    const today = new Date();
    const thisFullY = today.getFullYear();
    const thisYear = thisFullY % 100;
    const thisCent = Math.floor(thisFullY / 100);

    const propValue = props.getOwnFieldValue();

    if (!typeof name === "String") {
        throw new Error("A field must have a name");
    }

    const dobRequired = required ? ["year", "month", "day"] : [];

    const [value, setValue] = useState({});

    useEffect(() => {
        const isValidDate = isISODate(propValue);
        if ("today" === defaultvalue?.toLowerCase() && !isValidDate) {
            const newDate = getDateISOWithoutTime(new Date());
            parentHandleChange({
                target: { name, value: newDate },
                preventDefault: () => {}
            });
        } else if (!isValidDate && isISODate(defaultvalue)) {
            parentHandleChange({
                target: { name, value: defaultvalue },
                preventDefault: () => {}
            });
        } else if (isValidDate) {
            setValue(parseDateToObject(propValue));
        } else if (null !== propValue && undefined !== propValue) {
            parentHandleChange({
                target: { name, value: null },
                preventDefault: () => {}
            });
        }
    }, [defaultvalue, propValue]);

    const pivot = thisYear + Number.parseInt(pivotYAhead, 10);

    const maxDate = getMaxDate(props);
    const minDate = dateAdd(today, -maxDaysPrevious);

    const [error, setError] = useState(null);

    const parentHandleChangeLocal = dateSt => {
        parentHandleChange({
            target: { name, value: dateSt },
            preventDefault: () => {}
        });
    };

    const handleErrors = (newVal, newFullValue) => {
        const dt = convertToDate(newVal);

        if (dt.getTime() < minDate.getTime()) {
            setError(
                "Earliest date is " +
                    minDate.toLocaleDateString("en-GB", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                    })
            );
        } else if (dt.getTime() > maxDate.getTime()) {
            setError(
                "Latest date possible is " +
                    maxDate.toLocaleDateString("en-GB", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                    })
            );
        } else {

            if(getDateISOWithoutTime(dt) !== newFullValue){

                setError("Invalid date. Did you mean " + dt.toLocaleDateString("en-GB", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                }) + "?");

                parentHandleChangeLocal(null);
            }else{

                setError("");

                parentHandleChangeLocal(newFullValue);
            }
        }
    }

    const handleChange = (fName, fValue) => {
        const newVal = { ...value, [fName]: fValue };
        const { day, month, year } = newVal;
        // only change if this is a full valid date
        const newFullValue = year + "-" + month + "-" + day;
        if (isISODate(newFullValue)) {
            handleErrors(newVal, newFullValue);
        }else{
            setError("");
        }
    };

    const handleBlur = (fName, fValue) => {
        const newVal = { ...value, [fName]: fValue };
        const { day, month, year } = newVal;
        const newFullValue = year + "-" + month + "-" + day;
        if (isISODate(newFullValue)) {
            handleErrors(newVal, newFullValue);
        } else {
            parentHandleChangeLocal(null);
        }
    };

    const handleDayChange = (fName, fValue) => {
        const intVal = Number.parseInt(fValue, 10);
        if (intVal > 0 && intVal < 10) {
            setValue({ ...value, day: fValue });
        } else if (intVal > 9 && intVal < 32) {
            setValue({ ...value, day: fValue });
            handleChange(fName, intVal);
        } else if(isWhitespace(fValue) || fValue === "0") {
            setValue({ ...value, day: fValue });
        }else {
            setValue({ ...value });
        }
    };

    const handleDayBlur = (fName, fValue) => {
        if (isWhitespace(fValue)) {
            setValue({ ...value, day: "" });
            handleBlur(fName, "");
        } else {
            const newVal = paddInt(limit(fValue, 0, 31), 2);
            setValue({ ...value, day: newVal });
            handleBlur(fName, newVal);
        }
    };

    const handleMonthChange = (fName, fValue) => {
        const intVal = Number.parseInt(fValue, 10);
        if (intVal > 0 && intVal < 10) {
            setValue({ ...value, month: fValue });
        } else if (intVal > 9 && intVal < 13) {
            setValue({ ...value, month: fValue });
            handleChange(fName, intVal);
        } else if(isWhitespace(fValue) || fValue === "0") {
            setValue({ ...value, month: fValue });
        }else {
            setValue({ ...value });
        }
    };

    const handleMonthBlur = (fName, fValue) => {
        if (isWhitespace(fValue)) {
            setValue({ ...value, month: "" });
            handleBlur(fName, "");
        } else {
            const newVal = paddInt(limit(fValue, 1, 12), 2);
            setValue({ ...value, month: newVal });
            handleBlur(fName, newVal);
        }
    };

    const handleYearChange = (fName, fValue) => {
        const intVal = Number.parseInt(fValue, 10);
        if (intVal > 0 && intVal < 10000) {
            setValue({ ...value, year: fValue });
        }else if(isWhitespace(fValue) || fValue === "0") {
            setValue({ ...value, year: fValue });
        }
        if (intVal > 99 && intVal < 10000) {
            handleChange(fName, intVal);
        }
    };

    const handleYearBlur = (fName, fValue) => {
        if (isWhitespace(fValue) || fValue === "0") {
            setValue({ ...value, year: "" });
            handleBlur(fName, "");
        } else {
            const parsedYear = Number.parseInt(fValue, 10);
            let year;
            if (parsedYear <= pivot) {
                year = thisCent * 100 + Math.max(0, parsedYear);
            } else if (parsedYear < 100) {
                year = (thisCent - 1) * 100 + parsedYear;
            } else {
                year = parsedYear;
            }
            const newYear = Number.isNaN(year) ? "" : year;
            setValue({ ...value, year: newYear });
            handleBlur(fName, newYear);
        }
    };

    return (
        <div>
            <fieldset className="form__field form__field--full-width">
                <label className="form__label__date">
                    <legend>
                        {text}
                        {required && <span style={{ color: "red" }}> *</span>}
                    </legend>

                    <div className="form__date-wrapper">
                        <label
                            htmlFor={"day" + "_" + name}
                            className="form__label form__label--auto-width"
                        >
                            Day
                        </label>
                        <Input
                            className="form__input form__input--2-chars"
                            id={"day" + "_" + name}
                            name="day"
                            onBlur={handleDayBlur}
                            onChange={handleDayChange}
                            validate={validate}
                            maxlength="2"
                            placeholder=""
                            requiredNames={dobRequired}
                            values={value}
                            data-testid="day"
                        />

                        <label
                            htmlFor={"month" + "_" + name}
                            className="form__label form__label--auto-width"
                        >
                            Month
                        </label>
                        <Input
                            className="form__input form__input--2-chars"
                            id={"month" + "_" + name}
                            name="month"
                            onBlur={handleMonthBlur}
                            onChange={handleMonthChange}
                            validate={validate}
                            maxlength="2"
                            placeholder=""
                            requiredNames={dobRequired}
                            values={value}
                        />

                        <label
                            htmlFor={"year" + "_" + name}
                            className="form__label form__label--auto-width"
                        >
                            Year
                        </label>
                        <Input
                            className="form__input form__input--4-chars"
                            id={"year" + "_" + name}
                            name="year"
                            onBlur={handleYearBlur}
                            onChange={handleYearChange}
                            validate={validate}
                            maxlength="4"
                            placeholder=""
                            requiredNames={dobRequired}
                            values={value}
                        />
                    </div>
                </label>
            </fieldset>
            <div className="form__field form__field--full-width">
                <div className="form__label "></div>
                <div style={{ color: "red", width: "100%" }}>{error}</div>
            </div>
        </div>
    );
};

export default DateTextBox;
