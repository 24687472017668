import React, {useState, useEffect} from 'react';
import './styles.css';
import { isTrueOrStringTrue } from '../../lib/iprs-react-library/src/index';
import { BrandingKey_Feedback_Stars_Label_Text, BrandingKey_Show_Feedback_Stars_On_SelfReferral } from '../Branding/BrandingKeys';

const Feedback = ({ api, tokenContent, brandingInfo }) => {
    const [caseId, setCaseId] = useState(null);

    useEffect(()=>{
        setCaseId(tokenContent.CaseID)
    },[tokenContent])

    // 2. Checks for clicked stars
    const [stars, setStars] = useState([
        false, false, false, false, false
    ]);
    const [ratingScore, setRatingScore] = useState([])

    // const [emptyStars, setEmptyStars] = useState(false);
    const [starsLock, setStarsLock] = useState(false);

    const handleClickStar = () => {
        starsLock ? setStarsLock(false) : setStarsLock(true)
    }

    const handleHoverStar = (hoveredStar) => {
        if(starsLock === false){
            switch(hoveredStar){
                case 1:
                    setStars([true, false, false, false, false]);
                    setRatingScore(1);
                    break;
                case 2:
                    setStars([true, true, false, false, false]);
                    setRatingScore(2);
                    break;
                case 3:
                    setStars([true, true, true, false, false]);
                    setRatingScore(3);
                    break;
                case 4:
                    setStars([true, true, true, true, false]);
                    setRatingScore(4);
                    break;
                case 5:
                    setStars([true, true, true, true, true]);
                    setRatingScore(5);
                    break;
                default:
                    break;
            }
        }
    }

    const handleMouseOut = () => {
        if(starsLock === false){
            setStars([false, false, false, false, false]);
        } 
    }

    // 3. If star is clicked
    const [sendResponse, setSendResponse] = useState(null);
    const [thankyouMessage, setThankyouMessage] = useState();

    const sendFeedBack = (score) => {
        const params = {
            "CaseID": caseId,
            "Score": score
        }
        if(caseId !== null)
        {
            api.transact("CustomerCaseFeedbackCreate", params)
                .then(r => {
                    setSendResponse(r);  
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    useEffect(()=>{
        if(sendResponse !== null){
            sendResponse.apiResult === null ? setThankyouMessage(false) : setThankyouMessage(true);
        }
    },[sendResponse])

    useEffect(()=>{
        if(starsLock === true){
            sendFeedBack(ratingScore);
        } else {
            sendFeedBack(0);
        }
    // eslint-disable-next-line
    },[starsLock])

    const displayRatingDiv = isTrueOrStringTrue( brandingInfo[BrandingKey_Show_Feedback_Stars_On_SelfReferral], true );
    const feedbackLabelText = brandingInfo[BrandingKey_Feedback_Stars_Label_Text];

    return (
        <>
            <div className="typography">
                <div 
                    className="container"
                    style={{
                        paddingTop: '55px',
                        marginBottom: '88px',
                        fontSize: '1.8rem',
                        maxWidth: '664px',
                    }}
                >
                    <div className="booking-successful-page__tick">
                        <svg width="118" height="118" viewBox="0 0 118 118">
                            <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_90" data-name="Rectangle 90" width="76.471" height="77.221" transform="translate(0 0)" fill="none" stroke="#8db147" strokeWidth="1"></rect>
                            </clipPath>
                            </defs>
                            <circle id="Ellipse_27" data-name="Ellipse 27" cx="59" cy="59" r="59" fill="#f0f0f0"></circle>
                            <g id="Group_365" data-name="Group 365" transform="translate(20.765 20.389)">
                            <g id="Group_364" data-name="Group 364" clipPath="url(#clip-path)">
                            <path id="Path_267" data-name="Path 267" d="M31.2,31.134A17.373,17.373,0,1,1,6.574,6.619a17.387,17.387,0,0,1,29.7,12.258A17.24,17.24,0,0,1,31.2,31.134Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_268" data-name="Path 268" d="M27.588,21.789a31.622,31.622,0,0,0-18.072,0,4.749,4.749,0,0,0-3.058,4.224v5.006a17.413,17.413,0,0,0,5.558,3.827v0A17.3,17.3,0,0,0,25.09,35.1v0a17.3,17.3,0,0,0,5.556-3.466V26.013A4.738,4.738,0,0,0,27.588,21.789Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_269" data-name="Path 269" d="M18.552,7.971A6.145,6.145,0,1,0,24.7,14.116,6.145,6.145,0,0,0,18.552,7.971Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_270" data-name="Path 270" d="M69.9,70.6a17.34,17.34,0,1,1,5.072-12.257A17.268,17.268,0,0,1,69.9,70.6Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_271" data-name="Path 271" d="M66.289,61.25a31.622,31.622,0,0,0-18.072,0,4.749,4.749,0,0,0-3.058,4.224V70.48a17.394,17.394,0,0,0,5.558,3.826v0a17.29,17.29,0,0,0,13.074.258v0A17.3,17.3,0,0,0,69.347,71.1V65.474A4.738,4.738,0,0,0,66.289,61.25Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_272" data-name="Path 272" d="M57.253,47.431A6.145,6.145,0,1,0,63.4,53.576,6.145,6.145,0,0,0,57.253,47.431Z" fill="none" stroke="#8db147" strokeWidth="3"></path>
                            <path id="Path_273" data-name="Path 273" d="M70.261,31.394A25.491,25.491,0,0,0,44.554,7.41" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_274" data-name="Path 274" d="M7.4,44.378a25.489,25.489,0,0,0,24.86,24.86" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_275" data-name="Path 275" d="M3.256,48.521,7.4,44.378l4.144,4.143" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            <path id="Path_276" data-name="Path 276" d="M73.894,27.6l-4.143,4.143L65.607,27.6" fill="none" stroke="#8db147" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                            </g>
                            </g>
                        </svg>

                        <h1
                            style={{
                                paddingTop: '55px'
                            }}
                        >
                            Thank you
                        </h1>

                        <p 
                            className="intro__p"
                            style={{
                                marginBottom:"60px"
                            }}
                        >
                            Your chosen clinic has been confirmed and your referral sent to them. 
                            <br/>A SMS and email confirmation is on its way to you with all the clinic details.
                            <br/>
                                <span>
                                    Our network clinic will be in contact with you shortly.
                                </span>
                            <br/>
                                <span>
                                    All the best with your rehabilitation from the IPRS Health Team.
                                </span>
                        </p>


                        {displayRatingDiv === true ? 
                            <div>
                                <p id="IPRSRatingsText" className="bottom-paragraph">{thankyouMessage !== true ? feedbackLabelText : 'Thanks for your feedback'}</p>
                                
                                <div className="rating-stars">
                                    <button className="rating-star" onClick={()=>handleClickStar()} onMouseOver={()=>handleHoverStar(5)} onMouseOut={()=>handleMouseOut(5)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
                                            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                                                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill={stars[4] !== true ? '#636362' : '#F8D510'}></path>
                                            </g>
                                            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill={stars[4] === true ? '#F8D510' : 'none'}></path>
                                        </svg>
                                    </button>

                                    <button className="rating-star" onClick={()=>handleClickStar()} onMouseOver={()=>handleHoverStar(4)} onMouseOut={()=>handleMouseOut(4)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
                                            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                                                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill={stars[3] !== true ? '#636362' : '#F8D510'}></path>
                                            </g>
                                            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill={stars[3] === true ? '#F8D510' : 'none'}></path>
                                        </svg>
                                    </button>

                                    <button className="rating-star" onClick={()=>handleClickStar()} onMouseOver={()=>handleHoverStar(3)} onMouseOut={()=>handleMouseOut(3)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
                                            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                                                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill={stars[2] !== true ? '#636362' : '#F8D510'}></path>
                                            </g>
                                            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill={stars[2] === true ? '#F8D510' : 'none'}></path>
                                        </svg>
                                    </button>

                                    <button className="rating-star" onClick={()=>handleClickStar()} onMouseOver={()=>handleHoverStar(2)} onMouseOut={()=>handleMouseOut(2)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
                                            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                                                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill={stars[1] !== true ? '#636362' : '#F8D510'}></path>
                                            </g>
                                            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill={stars[1] === true ? '#F8D510' : 'none'}></path>
                                        </svg>
                                    </button>

                                    <button className="rating-star" onClick={()=>handleClickStar()} onMouseOver={()=>handleHoverStar(1)} onMouseOut={()=>handleMouseOut(1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
                                            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                                                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill={stars[0] !== true ? '#636362' : '#F8D510'}></path>
                                            </g>
                                            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill={stars[0] === true ? '#F8D510' : 'none'}></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        : <></>}
                        


                    </div>
                </div>
            </div>
        </>
    )
}

export default Feedback