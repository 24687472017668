/* 
 * Just a function to get the fields from the body of a JSON Web Token. Used
 * to get the party ID out of a login token.
 */


const decodeJWT = token => {
    
    let result = {}
    
    try{
        const parts         = token.split('.');
        const body          = parts[1];
        const decoded       = atob( body )
        
        result              = JSON.parse( decoded )
        
    }catch( e){
        result = false
    }
    
    return result
}

export default decodeJWT