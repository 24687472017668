import React, {useState, useEffect} from 'react'
import { getURLSearchParam } from '../../lib/iprs-react-library/src/index';
import { BrandKey_Payment_Cost } from '../Branding/BrandingKeys';

const virtual_physiotherapy = 3;

const Notice = ({styles, brandingInfo}) => {

    const [containerStyle, setContainerStyle] = useState({});

    const [pathway, setPathway] = useState(0);

    useEffect(()=>{
        setContainerStyle(styles.container);
        let pw = getURLSearchParam('pw'); 
        setPathway(pw);
    // eslint-disable-next-line
    },[])

    const paragraph = {
        fontSize: '1.2em',
        marginBottom: '1.5em'
    }

    // Function to check empty objects
    const isObjectEmpty = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)){
                return false;
            }
        }
        return true;
    }
    
    const [cancellationFeeValue, setCancellationFeeValue] = useState(0); 

    useEffect(()=>{
        const brandingObj = brandingInfo
        isObjectEmpty(brandingObj)
        if(!isObjectEmpty(brandingObj)){
            setCancellationFeeValue(brandingInfo[BrandKey_Payment_Cost])
        }
    },[brandingInfo])

    const [brandNameText, setBrandNameText] = useState('');

    useEffect(()=>{
        const brandingText = brandingInfo.BrandName !== undefined && brandingInfo.BrandName !== "Default" ? "with " + brandingInfo.BrandName : "";
        setBrandNameText(brandingText);
    },[brandingInfo])

    const PaymentNotice = () => {
       
            return pathway === virtual_physiotherapy ?
                <span style={{
                    lineHeight: 1.3
                }}>
                    <h2 style={{color: '#D75094', 
                                fontSize: '200%', 
                                fontWeight: 600, 
                                lineHeight: 3,
                                marginBottom: 0}}>
                        Arrange your Virtual Physiotherapy Appointment</h2>

                    <p style={{
                        fontSize: '150%',
                        marginBottom: '1em'
                    }}>
                        Before you book your virtual physiotherapy appointment we need to collect payment card details from you.
                    </p>

                    <p style={{
                        fontWeight: 600,
                        marginBottom: '1em'
                    }} >
                        Payment card details are taken in case you become liable for any costs in relation to your treatment. This could be for the following reasons:
                    </p>

                    <p style={paragraph}>
                        If your insurance policy is cancelled, lapsed, or expires and you continue to receive treatment, you would be responsible for the costs of the treatment from the point of no cover.
                    </p>

                    <p style={paragraph}>
                        If you cancel an appointment and fail to give 24 hours' notice that you are unable to attend, or if you do not show up for an appointment, you may be charged a no show fee, this is not covered by your insurance policy, so is passed on to you.
                    </p>

                    <p style={paragraph}>
                        If you have an excess on your policy or if you exceed any policy benefit limits your insurer will advise us of this and we will request a payment from you to cover these areas.
                    </p>

                    <p style={paragraph}>
                        To streamline your onward journey, we would like to validate a payment card, that we will hold securely and only use if one of the above scenarios occur during your treatment with us. To validate your card a £1.00 validation transaction is processed; however, this is refunded back to you, by IPRS Health.
                    </p>

                    <p style={paragraph}>
                        If IPRS Health do debit your card, we always provide you with 5 working days' notice, by email. The email will state the reasons why we need to debit the card, the amount, and our contact details in case you have any questions.
                    </p>

                    <p style={{
                        fontSize: '1.2em',
                        marginBottom: '2em'
                    }}>Please provide below the card details that you would like IPRS Health to validate.</p>
                </span> :
                <span
                    style={{
                        lineHeight: 1.2
                }}>
                    <h2 style={{
                        color: '#D75094', 
                        fontSize: '230%', 
                        fontWeight: 600, 
                        lineHeight: 3,
                        marginBottom: 0
                    }}>
                        Confirm your clinic
                    </h2>
                    <p style={{
                        fontSize: '150%',
                        marginBottom: '1em'
                    }}>
                        Before you confirm your IPRS Health clinic we need to collect payment card details from you.
                    </p>
                    <p style={{
                        fontWeight: 600,
                        marginBottom: '1em'
                    }} >
                        Payment card details are taken in case you become liable for any costs in relation to your treatment. This could be for the following reasons:
                    </p>

                    <p style={paragraph}>
                        If your insurance policy is cancelled, lapsed, or expires and you continue to receive treatment, you would be responsible for the costs of the treatment from the point of no cover.
                    </p>

                    <p style={paragraph}>
                        If you cancel an appointment with the clinic and fail to give 24 hours' notice that you are unable to attend, or if you do not show up for an appointment, you may be charged a no show fee of £{cancellationFeeValue}, this is not covered by your insurance policy, so is passed on to you.
                    </p>

                    <p style={paragraph}>
                        If you have an excess on your policy or if you exceed any policy benefit limits your insurer will advise us of this and we will request a payment from you to cover these areas.
                    </p>

                    <p style={paragraph}>
                        To streamline your onward journey, we would like to validate a payment card, that we will hold securely and only use if one of the above scenarios occur during your treatment with us. To validate your card a £1.00 validation transaction is processed; however, this is refunded back to you, by IPRS Health.
                    </p>

                    <p style={paragraph}>
                        If IPRS Health do debit your card, we always provide you with 5 working days' notice, by email. The email will state the reasons why we need to debit the card, the amount, and our contact details in case you have any questions.
                    </p>

                    <p style={{
                        fontSize: '1.2em',
                        marginBottom: '2em'
                    }}>Please provide below the card details that you would like IPRS Health to validate.</p>
                </span>;
        
    };

    return (
        <div className="container" style={containerStyle}>
            <div style={{marginTop: '1em'}}>
                <div className="typography">
                    <div style={{border:'1px solid black', padding:'15px', fontSize: '1em'}} id="PaymentMessage" name="PaymentMessage">

                        <PaymentNotice />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice