
const toBoolean = (value, defaultValue = false) => {

    let result = defaultValue;
    if (true === value) {
        result = true;
    } else if (false === value) {
        result = false;
    } else {
        const trimLC = ('' + value).toLocaleLowerCase().trim();
        if (trimLC === 'true' || trimLC === 'yes' || trimLC === '1') {
            result = true;
        } else if (trimLC === 'false' || trimLC === 'no' || trimLC === '0') {
            result = false;
        }
    }

    return result;
}

export default toBoolean;
