import React from 'react'
import { useEffect, useState } from 'react';
import Check from '../../images/check.svg';
import {getURLSearchParam, LoadingIndicator} from '../../lib/iprs-react-library/src/index';

const PaymentSuccess = ({nextURL, api, token}) => {

    const [facilityId, setFacilityId] = useState('')
    const [makeReferralError, setMakeReferralError] = useState('');
    const [isMakeReferralLoading, setIsMakeReferralLoading] = useState(false)
 
    useEffect(()=>{
        const urlParamFacilityId = getURLSearchParam('FacilityID')
        setFacilityId(urlParamFacilityId)
    },[])

    // Redirecting to feedback page after making an API call
    const apiMakeReferral = (token, facilityId) => {
        setMakeReferralError('');
        const params = {
            "Token": token,
            "FacilityID" : facilityId
        }
        api.transact("MakeReferral", params)
            .then(r => {
                setIsMakeReferralLoading(false);
                console.log("MakeReferral: ", r)
                const urlForFeedBack = '../feedback/' 
                    + '?Token=' + token 
                    + '&FacilityID=' + facilityId;
                window.location.replace(urlForFeedBack);
            })
            .catch(error => {
                setIsMakeReferralLoading(false);
                console.log(error)
                if(error.apiText !== undefined){
                    setMakeReferralError(error.apiText);
                }
            });
    }

    const confirmClinic = () => {
        apiMakeReferral(token, facilityId);
        setIsMakeReferralLoading(true);
    }

    return (
        <form>
            <div 
                className='form__group paymentMessage'
                style={{
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center'
                }}
            >
                <img 
                    src={Check} 
                    alt='Check icon' 
                    style={{
                        width: '100px',
                        margin: 'auto',
                        marginBottom: '20px'
                    }}
                />
                <div 
                    className="form__title"
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    <h2
                        style={{
                            color: '#B8397A',
                            margin: '0.75em 0',
                            fontWeight: '600',
                            fontSize: '150%'
                        }}
                    >Your payment card was authenticated.</h2>
                </div>
                <p
                    style={{
                        paddingBottom: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}
                >
                    Thank you, your payment card was successfully authenticated.<br/>
                    Now click the button to confirm your IPRS Health Clinic
                </p>

                <>
                    <button
                        type="button" 
                        className="payment form__action" 
                        disabled={facilityId === '' || isMakeReferralLoading === true  ? true : false}
                        onClick={confirmClinic}
                        style={{
                            margin: 'auto',
                            height: '42px'
                        }}
                    >
                        {isMakeReferralLoading !== true ? 
                            "Confirm Clinic" : 
                            <LoadingIndicator size={20}
                                    loading={true} 
                                    color={"#FFFFFF"}
                            />}
                    </button>;
                </>

                <p
                    style={{marginTop: "20px"}}
                >{makeReferralError}</p>
            </div>
        </form>
    )
    }

export default PaymentSuccess