/* 
 * React class to dislay a loading indicator
 */
import ClipLoader                           from 'react-spinners/ClipLoader';
import TwoSwitch                            from './TwoSwitch';
import React                                from 'react'; 

const LoadingIndicator = ({loading, color = '#000000', speed=1, size=35}) => {
    
    return <TwoSwitch test={loading}>
        <ClipLoader loading={true} size={size} color={color} speedMultiplier={speed}/>
        <span style={{width: '35px', height: '35px', display: 'inline-block'}} ></span>
    </TwoSwitch>
        
}

export default LoadingIndicator;
