import React, {useState}     from    'react';
import { useEffect } from 'react';
import { getDateISOWithoutTime } from '../../shared/DateUtil';
import {default as Input}    from    '../FormInput';

const today         = new Date();

const DateBox = (props) => {

    const { name,
            text,
            handleChange: parentHandleChange, 
            validating, 
            required,
            maxDaysAhead = null, 
            maxDaysPrevious = null,
            Value,
            validationWarning,
            enable
    } = props;

    const value = props.getOwnFieldValue(); 

    const [error,setError] = useState(null);
    const [dateValue, setDateValue] = useState(Value ?? value);

    useEffect(()=>{
        if(dateValue !== value){
            setDateValue(value);
        }
    },[value])

    const calculateDate = (today, operator, daysValue) => {

        const result = new Date(today);

        if(operator === '+'){
            result.setDate(result.getDate() + daysValue);
        } else if (operator === '-'){
            result.setDate(result.getDate() - daysValue);
        }

        return result;
    }

    const onChangeHandler = (e) => {
        parentHandleChange({target: {name: e.target.name, value: e.target.value}});
        const newDateValue = e.target.value
        if(value === undefined || value === ''){
            setDateValue(newDateValue ?? Value);
        } else {
            setDateValue(value);
        }
    }
    
    let daysAhead; 
    let daysPrevious;
    let dateInput;

    const [textBoxClassName, setTextBoxClassName] = useState("form__input");

    // disabledField will be set to false if enable is not undefined and set to 'true'
    const disabledField = enable !== undefined && enable === 'true' ? false : enable !== undefined ? true : false;


    // Date conversion function for cases where data from API has given date string in 'DD/MM/YYYY' format
    // or 'YYYY-MM-DDT00:00:00' format. This is needed due to type='date' only accepts date string in
    // 'YYYY-MM-DD' format.
    const dateConversion = (valueToConvert) => {

        if(value === undefined || value === ''){
            valueToConvert = Value;
        }

        // default value for input type='date' should be an empty string.
        let convertedValue;

        if(valueToConvert === undefined){
            convertedValue = '' ;
        }

        // date value conversion should only happen when valueToConvert is not undefined or empty string
        if(convertedValue !== ''){
  
            if(valueToConvert?.includes('/')){
                const [day, month, year] = valueToConvert.split("/");
                const dateObj = new Date(year, month - 1, day);
                convertedValue = dateObj.toISOString().substring(0, 10);
            } else if (valueToConvert?.includes('T')){
                const dateObj = new Date(valueToConvert);
                convertedValue = dateObj.toISOString().substring(0, 10);
            } else {
                const dateObj = new Date(valueToConvert);
                convertedValue = dateObj.toISOString().substring(0, 10);
            }
        }
        return convertedValue;
    }

    let convertedDateValue = dateConversion(value)

    // If maxDaysAhead and maxDaysPrevious is present, dateInput will have a variable with additional attribute 
    // in the <input />
    if(maxDaysAhead === null && maxDaysPrevious === null){
        dateInput = <input 
                        className={textBoxClassName}
                        type="date" 
                        name={name}
                        value={convertedDateValue}
                        onChange={e=>onChangeHandler(e)}
                        disabled={disabledField}
                    />;
    } else {

        daysAhead = calculateDate(today, '+', maxDaysAhead); 
        daysPrevious = calculateDate(today, '-', maxDaysPrevious);

        dateInput = <input 
                        className={textBoxClassName}
                        type="date" 
                        name={name}
                        // defaultValue={dateValue}
                        value={convertedDateValue}
                        onChange={e=>onChangeHandler(e)}
                        min={daysPrevious.toISOString().substring(0, 10)}
                        max={daysAhead.toISOString().substring(0, 10)} 
                        disabled={disabledField}
                    />;
    }

    // depending on dateValue, useEffect runs to put highlights to the input field border
    // and also sets the dateValue with a string which is time truncated from the original datetime ISO format string.
    // If dateValue is empty and the field's required property is true, it will set the error to 'required field is empty'.
    useEffect(()=>{

        let initRequiredString = "";

        initRequiredString = required;

        const requiredString = initRequiredString === undefined ? "false" : initRequiredString?.toString().toLowerCase();

        if(dateValue === undefined || dateValue === ""){
            if( requiredString === "true"){
                setTextBoxClassName("form__input input-validate-highlight");
            }
        } else {
            setTextBoxClassName("form__input");
        }

        if(dateValue !== undefined && dateValue.length > 10){
            setDateValue(dateValue.substring(0,10));
        }

        // When the field is required, dateValue needs to have some value.
        if(dateValue === undefined || dateValue === "" || dateValue === '' || dateValue === null && requiredString === true){
            setError('Required field is empty');
        } else if (dateValue !== undefined || dateValue !== "" || dateValue !== null && requiredString === true){
            setError(null);
        }

    },[dateValue])


    const [validationWarningMessage, setValidationWarningMessage] = useState(<></>);

    useEffect(()=>{
        if(validationWarning !== undefined){
            if (error !== null){
                setValidationWarningMessage(<div className="alert required">{validationWarning}</div>)
            } else if(error === null || error === ""){
                setValidationWarningMessage(<></>);
            } else {
                setValidationWarningMessage(<></>);
            }
        }
    },[error])

    return (
        <>
            <div className="form__field form__field--full-width">
                <label className="form__label" htmlFor={name}>
                    {text}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>
                {dateInput}
            </div>
            { validationWarningMessage }
        </>
    )
}

export default DateBox