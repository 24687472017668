import React, { useState } from 'react';
import { useEffect } from 'react';

const count = 5;
const ar = new Array(count).fill(null);

const MSG_RATE = 'Rate your assessment today: ';
const MSG_UPLOADING = 'Uploading';
const MSG_THANKS = 'Thanks for your feedback';
const MSG_RETRY = 'Sorry there was a problem. Rate your assessment today again: ';

/**
 * This was taken from the diary project
 * */
const CustomerCaseFeedbackStars = ({ api, caseID }) => {
    
    // a score of zero means no score 
    const [score, setScore] = useState(0);

    const [scoreCaptured, setScoreCaptured] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [err, setErr] = useState(false);

    let msg;
    if (uploading) {
        msg = MSG_UPLOADING;
    } else if(err){
        msg = MSG_RETRY;
    }else if (scoreCaptured) {
        msg = MSG_THANKS;
    } else {
        msg = MSG_RATE;
    }

    useEffect(() => {
        setUploading(true);
        api.transact('CustomerCaseFeedbackGet', {
            CaseID: caseID
        })
            .then(r => r.apiResult)
            .then(r => processScoreResponse(r) )
            .catch(err => {
                setErr(true);
                setUploading(false);
            })
    },
    []);

    const buttonEventNumber = (scoreButtonNum, eventType) => {

        console.log('event', scoreButtonNum, eventType);

        if ('click' === eventType) {
            if (scoreCaptured) {
                setScore(0);
                uploadScore(0);
            } else {
                setScore(scoreButtonNum);
                uploadScore(scoreButtonNum);
            }
            setScoreCaptured(!scoreCaptured);
        } else if ('mouseenter' == eventType) {
            if (!scoreCaptured) {
                setScore(scoreButtonNum);
            }
        } else {
            if (!scoreCaptured) {
                setScore(0);
            }
        }
    }

    const uploadScore = score => {

        setUploading(true);
        api.transact('CustomerCaseFeedbackCreate', {
            CaseID: caseID, Score: score
        })
            .then(r => r.apiResult)
            .then(r => processScoreResponse(r) )
            .catch(err => {
                setScoreCaptured(false);
                setScore(0);
                setErr(true);
                setUploading(false);
            });   
    }

    const processScoreResponse = r => {

        if (null === r) {
            // null response means no feedback stored
            setScoreCaptured(false);
            setScore(0);
        } else {
            setScore(r.Score);
            setScoreCaptured(true);
        }
        setErr(false);
        setUploading(false);
    }

    const cont = ar.map((v,i) => (
        <StarButton selected={i < score} index={i + 1} key={i} buttonEventNumber={buttonEventNumber}/>
    ));

    return <>
        <p id="IPRSRatingsText" className="bottom-paragraph">{ msg }</p>
        <div className="rating-stars" id="IPRSRatings" ng-controller="FeedbackController" ng-init="init()">
            { cont }
        </div>
    </>
}

const StarButton = ({ selected, buttonEventNumber, index }) => {

    let className = 'rating-star';
    if (selected) {
        className += ' filled';
    }

    const buttonEventHandler = e => {
        e.preventDefault();
        buttonEventNumber(index, e.type);
    }

    return <button className={className} onClick={buttonEventHandler} onMouseEnter={buttonEventHandler} onMouseLeave={buttonEventHandler} >
        <svg xmlns="http://www.w3.org/2000/svg" width="24.184" height="23" viewBox="0 0 24.184 23">
            <g id="Icon_metro-star-empty" data-name="Icon metro-star-empty" transform="translate(0)">
                <path className="star-border" id="Icon_metro-star-empty-2" data-name="Icon metro-star-empty" d="M26.754,11.468,18.4,10.254,14.663,2.683l-3.737,7.571L2.571,11.468l6.046,5.893L7.189,25.683l7.473-3.929,7.473,3.929-1.427-8.321,6.046-5.893ZM14.663,19.886,9.385,22.661l1.008-5.877-4.27-4.162,5.9-.857,2.639-5.347L17.3,11.765l5.9.857-4.27,4.162,1.008,5.877-5.277-2.775Z" transform="translate(-2.57 -2.683)" fill="#636362"></path>
            </g>
            <path className="star-fill" id="Path_281" data-name="Path 281" d="M15276.76-84.847l6.362-1.01,2.9-6.109,3.177,6.109,6.859,1.01-5.2,5.095,1.72,6.79-6.556-2.808-6.132,2.808,1.245-6.79Z" transform="translate(-15273.949 94)" fill="none"></path>
        </svg>
    </button>
}

export default CustomerCaseFeedbackStars;
