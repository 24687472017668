import React, { useState, useEffect, useCallback }     from 'react';
import { getURLSearchParam }        from '../../lib/iprs-react-library/src/index';

import FormWrapper from './FormWrapper';
import ErrorMessage from './ErrorMessage';
import Notice from './Notice';

const Payment = ({ api, tokenContent, brandingInfo }) => {

    const [err, setErr] = useState('');
    const [URLToken, setURLToken] = useState('');
    const [decodedToken, setDecodedToken] = useState('');
    const [expiryTime, setExpiryTime] = useState('');
    const [currentTime, setCurrentTime] = useState(new Date());
    const [facilityId, setFacilityId] = useState('');

    // 0. - Checks all the status to decide whether to show the page or not
    //      - Does it have Url Token?
    //      - Does it have Decoded Token?
    //      - Does it have expiry Time?
    const [pageStatus, setPageStatus] = useState({
        'URLToken': [false, ''], 
        'Decoded Token': [false, ''], 
        'expiryTime': [false, '']
    })

    // 0.1a - Checks for URLToken and sets pageStatus state
    useEffect(()=>{
        const newPageStatus = {
            ...pageStatus, 
            'URLToken': [true, URLToken]
        }

        const prevPageStatus = {
            ...pageStatus
        }

        if(URLToken !== '' && JSON.stringify(newPageStatus) !== JSON.stringify(prevPageStatus))
        setPageStatus(newPageStatus);
    },[URLToken, pageStatus])

    // 0.2 - Checks for decodedToken and sets pageStatus state
    useEffect(()=>{
        const newPageStatus = {
            ...pageStatus, 
            'Decoded Token': [true, decodedToken]
        }

        const prevPageStatus = {
            ...pageStatus
        }

        if(decodedToken !== '' && JSON.stringify(newPageStatus) !== JSON.stringify(prevPageStatus)){
            setPageStatus(newPageStatus);
        }
    },[decodedToken, pageStatus])

    // 0.3 - Function that calculates and determines whether token is expired or not.
    const [tokenValidity, setTokenValidity] = useState(false); 
    const expiryTimeCalculator = (ct, et) => {
        const timeDifference = (et * 1000) - ct;
        if (timeDifference < 0 || timeDifference === 0) {
            setTokenValidity(false);
            return false
        } else {
            setTokenValidity(true);
            return true
        }
    }

    // 0.4 - Checks for decodedToken and sets pageStatus state
    // Conditional Statement uses expiryTimeCalculator() above to get boolean
    // which will be set to false if expired.
    const pageStatusExpiryTimeUpdate = useCallback(() => {
        if(expiryTimeCalculator(currentTime, expiryTime)){

            const newPageStatus = {
                ...pageStatus, 
                'expiryTime': [true, expiryTime]
            }

            const prevPageStatus = {
                ...pageStatus
            }

            if(JSON.stringify(newPageStatus) !== JSON.stringify(prevPageStatus)){
                setPageStatus(newPageStatus)
            }

        }
    },[currentTime, expiryTime, pageStatus])

    useEffect(()=>{
        pageStatusExpiryTimeUpdate();
    },[expiryTime, currentTime, pageStatusExpiryTimeUpdate])

    // 0.5 - Checks if all the required page status is true.
    //     If 'URLToken', 'Decoded Token' and 'expiryTime' is set to true,
    //     'ExpiryStatus' will be set to true and displays the payment form.
    useEffect(()=>{
        if(pageStatus['URLToken'][0] === true && 
           pageStatus['Decoded Token'][0] === true && 
           pageStatus['expiryTime'][0] === true){
            setDisplayForm(true)
        } else {
            setDisplayForm(false)
        }
    },[URLToken, decodedToken, expiryTime, pageStatus])

    // 0.6 - State that determines whether to display the form or not.
    const [displayForm, setDisplayForm] = useState(false)

    // 0.7 - Countdown Timer to check whether token has expired.
    //       Check will be carried out for every seconds elapsed after loading
    useEffect(()=>{
        const interval = setInterval(()=>{
            setCurrentTime(Math.floor(new Date().getTime() / 1000));
        }, 1000)
        return () => clearInterval(interval);
    })

    useEffect(()=>{
        if(expiryTime){
            const remainingTime = expiryTime - currentTime;
            if(remainingTime <= 0){
                setDisplayForm(false);
            }
        }
    },[currentTime, expiryTime])

    // 1. Retrieves token from URL
    // and sets the facilityId from URL Parameter
    const getAndSetToken = useCallback(() => {
        let t = api.getToken();
        if(t){   
            // console.log('api object has token')
            setURLToken(t);
        } else {
            // console.log('api object doesnt have token,')
            setURLToken(getURLSearchParam('Token'));
        }
    },[api])

    useEffect(()=>{
        const urlFacilityId = getURLSearchParam('FacilityID');
        if(urlFacilityId !== ''){
            setFacilityId(urlFacilityId);
        }

        getAndSetToken();

    }, [getAndSetToken]);

    // 2. Validates Token and sets err state    
    useEffect(()=>{

        if(URLToken !== ''){
            setDecodedToken(tokenContent);
            setErr('No Error');
        } else {
            setErr('No token');
        }

    },[URLToken, tokenContent]);

    // 4. Sets the expiry time
    useEffect(()=>{
        // console.log('tokenContent: ', tokenContent)
        setExpiryTime(decodedToken.exp);
    },[decodedToken])

    // 5. Get Next URL for success page
    // const [nextURL, setNextURL] = useState({})
    const nextURL = null;

    const [displayNotice, setDisplayNotice] = useState(true);
    const styles = {
        container: {maxWidth: '960px'},
        header: {borderBottom: '0px'},
        header__inner: {padding: '20px', borderBottom: '1px solid #d5579b'},
        header__p: {fontWeight:500, fontSize:'2.4rem'}
    }

    let content = (
            <>
                {displayNotice ? <Notice styles={styles} brandingInfo={brandingInfo}/> : <></>}
                <FormWrapper 
                    setDisplayNotice={setDisplayNotice} 
                    api={api} 
                    nextURL={nextURL} 
                    facilityId={facilityId}
                    brandingInfo={brandingInfo}
                />
            </>
    );

    return (
        <div style={{fontFamily: 'Titillium Web,verdana,sans-serif'}}>

            <div style={{padding: '20px 0px'}}>
                <ErrorMessage tokenValidity={tokenValidity} err={err} styles={styles}/>
                {displayForm === true ? content : <></>}
            </div>

        </div>
    )
}

export default Payment