import React                                           from 'react'; 

/* 
 * Simple binary switch component
 */

const TwoSwitch = (props) => {

    /* note that children is just a component if only one and array like if more */

    const childrenCount = React.Children.count(props.children);
    if( childrenCount > 1 ){
        return <>{props.test? props.children[0]: props.children[1]}</>;
    }else if (childrenCount > 0 && props.test){
        return <>{props.children}</>;
    }else{
        return null;
    };
}

export default TwoSwitch;
