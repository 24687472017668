import React, { useState } from 'react';
import { OKCancel } from '../util/Buttons';

// indicates which site sent the request
const originID = parseInt( process.env.REACT_APP_PASSWORD_RESET_ORIGIN, 10 ) || 3;

const PasswordResetRequestForm = ({ onCancel, done, api }) => { 

    const [username, setUsername]           = useState('');
    
    const [error, setError]                 = useState(null);
    
    const handleOK = () => {
        
        api.transact('RequestPasswordReset', {
            username: username,
            origin: originID
        })
        .then( r => done() )
        .catch( err => {
            setError( err.message );
        })
    }

    return  <div>
        <form>
            <label htmlFor='username'   className='form__label'  >Email Address</label>
            <input name='username'      className='form__field'  
                autoComplete='off'
                onChange={e => setUsername(e.target.value)} value={username} />

            <OKCancel onOK={handleOK} okText='Request Password Reset' onCancel={onCancel} />
            
            <legend>Please enter your email address and click <strong>'Request Password Reset'</strong>. We
                will send you an email that will assist you in resetting your password.</legend>
        </form>
        <p>{error}</p>
   </div>
}

export default PasswordResetRequestForm;
