import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/Application/App";
import reportWebVitals from "./reportWebVitals";
import { getURLPathArray, getURLSearchParam } from "./lib/iprs-react-library/src/index.js";
import { BrowserRouter as Router } from "react-router-dom";

import API from "iprs-api-library";

const apiURL = process.env.REACT_APP_IPRS_WEB_API_URL;
const api = new API(null, apiURL);

// Trialing the synthetics code
const id = "getsyn";
const scriptLocation = document.getElementById(id);

if (!scriptLocation) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.synthetix.com/penfield/get_synthetix.js?applicationkey=09eb27883c6b2441a1265146337f0a63&consumerkey=d55c9df3224767eca9631494cb1d7bab";
    script.id = id;
    script.async = true;
    script.onload = () => {
        console.log('loading synthetix()', window.synthetix);
        window.synthetix();
    };
    script.onerror = (message, source, lineno, colno, error) => {
        console.log("chat script failed");
    };
    document.body.appendChild(script);
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <App
            getURLSearchParam={getURLSearchParam}
            api={api}
            getURLPathArray={getURLPathArray}
        />
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
