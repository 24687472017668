import React, {useEffect, useState} from 'react'
// import {getURLSearchParam, decodeJWT, LoadingIndicator} from '../../../lib/iprs-react-library/src/index.js';
import {getURLSearchParam, decodeJWT, LoadingIndicator} from '../../lib/iprs-react-library/src/index';
import './styles.css';
import ExpireTimer from './ExpireTimer';
import ConfirmCheckBox from './ConfirmCheckBox';
import DefaultMessage from './DefaultMessage';
import PhoneNumber from '../PhoneNumber';
import { DigitalTriageService, BookedTCAService, ImmediateTCAService, 
    MedicalReferralService, MedReferralChooseAndBookService } 
    from './ServiceConst';

const AvivaHealthBacktoBetterESSENTIALSClient = 349910;

const ReferralForm = ({ api, tokenContent, brandingInfo }) => {

    /* Not quite a case ID just a pending one. 
    Warning: some of the API calls call it CaseID, The 
    dotnet 4 API would have sent as CaseID so use that 
    if present for backward compatibility. */
    const pendingCaseID = tokenContent?.IPRSPendingCaseID?? tokenContent?.CaseID;
    const expireTime = tokenContent?.exp;

    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState('');
    
    const [retrievedCase, setRetrievedCase] = useState('');

    const [firstName, setFirstName] = useState('');
    const [surname, setSurName] = useState('');
    const [birthDate, setBirthDate] = useState({Day: '', Month: '', Year: ''});
    const [caseReference, setCaseReference] = useState(''); 
    const [claimNumber, setClaimNumber] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [town, setTown] = useState('');
    const [county, setCounty] = useState('');
    const [postcode, setPostcode] = useState('');
    const [email, setEmail] = useState(['','']);
    const [injury, setInjury] = useState('');
    const [injuryOverview, setInjuryOverview] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [service, setService] = useState('');

    const [injuryList, setInjuryList] = useState('');
    const [injuryOption, setInjuryOption] = useState('');
    
    const [expiryStatus, setExpiryStatus] = useState('checking');
    const [apiResult, setApiResult] = useState([
        ['token', 'pending'],
        ['tokenValidation', 'pending'],
        ['injuryList', 'pending'],
        ['caseRetrieval', 'pending']
    ]);

    // Checking checkbox ticks. 
    const [checkBox, setCheckBox] = useState(false)

    // 0. Status Monitor to assess the API requests made
    useEffect(()=>{
        setApiResult([
            ['token', token ? 'success' : 'pending', token],
            ['tokenValidation', pendingCaseID ? 'success' : 'pending', ['CaseID', pendingCaseID], ['exp', expireTime]],
            ['injuryList', injuryList ? 'success' : 'pending'],
            ['caseRetrieval', retrievedCase ? 'success' : 'pending'] 
        ])
    },[token, pendingCaseID, expireTime, injuryList, retrievedCase])

    // 1. Retrieves the Token from URL when this component is loaded
    useEffect(()=>{
        if(getURLSearchParam('Token')){
            setToken(getURLSearchParam('Token'));
        }
    }, []);

    // 3. This useEffect runs when caseId is changed which will happen 
    //    when api.validateLoggedIn(token) request is successful and caseId state is changed
    //    It will make api.transact("RetrieveAvivaCase", params) request and if successful,
    //    retrievedCase state will be set with json response received.

    const [clientID, setClientID] = useState('')

    useEffect(() => {
        if (pendingCaseID) {
            const params = {
                CaseID: pendingCaseID
            };

            setLoading(true);
            api.transact("RetrieveAvivaCase", params)
                .then(r => r.apiResult)
                .then(r => {
                    setRetrievedCase(r);
                    setClientID(r.ClientID);
                    return api.transact("InjuryRegionList", {});
                })
                .then(r => r.apiResult)
                .then(r => {
                    setInjuryList(r);
                    setLoading(false);
                    setErr("");
                })
                .catch(err => {
                    setErr(err.message);
                    setLoading(false);
                });
        }
    }, [pendingCaseID]);

    // 3.1 sets the states needed to populate the form when response is received
    useEffect(()=>{
        if(retrievedCase){
            setFirstName(retrievedCase.FirstName);
            setSurName(retrievedCase.LastName);
            setBirthDate(retrievedCase.BirthDate);
            setClaimNumber(retrievedCase.ClaimReference);
            setPolicyNumber(retrievedCase.PolicyNumber);
            setAddress1(retrievedCase.Address.Address1);
            setAddress2(retrievedCase.Address.Address2);
            setTown(retrievedCase.Address.Town);
            setCounty(retrievedCase.Address.County);
            setPostcode(retrievedCase.Address.Postcode);
            setInjury(retrievedCase.InjuryRegionID);
            setInjuryOverview(retrievedCase.InjuryOverview);
        }
    },[retrievedCase]);

    // 5. Compares injury code from the case with injury list retrieved
    //    and creates a select option for the form when matching pkRegionId is found.  
    useEffect(()=>{
        if(injuryList && injury){
            injuryList.map( x => {
                if (x.pkRegionId===injury){
                    setInjuryOption(
                        <>
                            <option value={x.pkRegionId}>
                                {x.NonMedicalRegion}
                            </option>
                        </>
                    );
                }
            })
        }
    },[injuryList, injury])

    const [formMessage, setFormMessage] = useState('You are not authorised to view this page.')

    // Checks for expiryStatus and determines what message to be displayed or 
    // disables the form when status is 'expired'
    useEffect(()=>{
        switch(expiryStatus){
            case 'checking':
                setFormMessage('')
                break;
            case '<5mins':
                setFormMessage('This page expires in 5 minutes.');
                break;
            case 'expired':
                setFormMessage('This page has now expired.');
                break;
            case 'valid':
                setFormMessage('');
                break;
            default:
                setFormMessage('');
        }
    }, [expiryStatus])

    const formStatus = () => {
        return (
            <div className="formStatus">
                {!token ? <p id="warning">You are not authorised to view this page.</p> : <></>}
                <ExpireTimer 
                    expireTime={expireTime} 
                    setExpiryStatus={setExpiryStatus} 
                    formMessage={formMessage}
                />
            </div>
        )
    }

    // Email Validation
    const validateEmail = (e) => {
        if(e.target.value !== '' && e.target.validity.valid){
            setEmail(['Valid', e.target.value]);
        } else {
            setEmail(['Invalid', e.target.value]);
        }
    }

    // Phone number validation
    const [mobileNo, setMobileNo] = useState(['','']);
    const [homeTelNo, setHomeTelNo] = useState(['','']);

    const validateNumber = (e) => {
        if(e.target.id === 'mobNo'){
            if(e.target.value !== '' && e.target.validity.valid){
                setMobileNo(['Valid', e.target.value]);
            } else {
                setMobileNo(['Invalid', e.target.value]);
            }
        } else if (e.target.id === 'landNo'){
            if(e.target.value !== '' && e.target.validity.valid){
                setHomeTelNo(['Valid', e.target.value]);
            } else {
                setHomeTelNo(['Invalid', e.target.value]);
            }
        }
    }

    // Additional Notes Validation
    const validateNote = (e) => {
        if(e.target.value !== '' && e.target.validity.valid){
            setAdditionalNotes(['Valid', e.target.value]);
        } else {
            setAdditionalNotes(['Invalid', e.target.value])
        }
    }


    // Initialising state for validation checks
    const [validationChecks, setValidationChecks] = useState([
        ['email', ['','']],
        ['mobNo', ['','']],
        ['landNo', ['','']],
        ['additionalNotes', ['','']],
        ['Service', ['','']]
        ['checkBox', '']
    ]);

    // Validation Checker for 5 fields and a checkbox.
    useEffect(()=>{
        setValidationChecks([
            ['email', email],
            ['mobNo', mobileNo],
            ['landNo', homeTelNo],
            ['additionalNotes', additionalNotes],
            ['service', ['Valid', service]],
            ['checkBox', checkBox]
        ])
    }, [email, mobileNo, homeTelNo, additionalNotes, service, checkBox])

    
    // Required Input Field Star at the end of label based on Service option
    const [requiredFieldCheck, setRequiredFieldCheck] = useState([
        ['email',false],
        ['mobNo',false],
        ['landNo',false],
    ])

    // Checks for change in service option and put stars on label
    useEffect(()=>{
        switch(service){
            case '':
                setRequiredFieldCheck([
                    ['email',false],
                    ['mobNo',false],
                    ['landNo',false],
                ]);
                break ;
            case DigitalTriageService :
                if(validationChecks[0][1][0] === 'Valid'){
                    setRequiredFieldCheck([
                        ['email',true],
                        ['mobNo',false],
                        ['landNo',false],
                    ]);
                } else if(validationChecks[1][1][0] === 'Valid'){
                    setRequiredFieldCheck([
                        ['email', false],
                        ['mobNo', true],
                        ['landNo', false]
                    ]);
                } else {
                    setRequiredFieldCheck([
                        ['email',true],
                        ['mobNo',true],
                        ['landNo',false],
                    ]);
                }
                break ;
            default :
                if(validationChecks[1][1][0] === 'Valid'){
                    setRequiredFieldCheck([
                        ['email',false],
                        ['mobNo',true],
                        ['landNo',false]
                    ])
                } else if (validationChecks[2][1][0] === 'Valid'){
                    setRequiredFieldCheck([
                        ['email',false],
                        ['mobNo',false],
                        ['landNo',true]
                    ])
                } else {
                    setRequiredFieldCheck([
                        ['email',false],
                        ['mobNo',true],
                        ['landNo',true],
                    ]);
                }
                break ;
        }
    },[service, validationChecks])

    // Confirm button 
    const [confirm, setConfirm] = useState(false)

    // Checks validationChecks state to determine whether confirm button to be undisabled. 
    useEffect(()=>{
       
        // If all fields are valid and service check box is ticked, confirm button
        // will be undisabled.
        if(validationChecks[0][1][0] === 'Valid' && 
           validationChecks[1][1][0] === 'Valid' &&
           validationChecks[2][1][0] === 'Valid' &&
           validationChecks[4][1][0] === 'Valid' &&
           validationChecks[5][0] === true){
            setConfirm(true)
        } else if(service === DigitalTriageService){
            // When service === 'DigitalTriage', required fields are email and ticked checkbox.
            if((validationChecks[0][1][0] === 'Valid' || validationChecks[1][1][0] === 'Valid') && 
                validationChecks[5][1] === true){
                    setConfirm(true);
            } else if (
                validationChecks[0][1][0] === 'Invalid' || 
                validationChecks[5][1] === false){
                    setConfirm(false);
            } else {
                setConfirm(false);
            }
        } else if(service===BookedTCAService || service===MedReferralChooseAndBookService){
            // When service is BookedTCA, required fields are mobNo or landNo
            if( validationChecks[1][1][0] === 'Valid' || validationChecks[2][1][0] === 'Valid'){
                setConfirm(true);
            } else if (
                validationChecks[1][1][0] === 'Invalid' ||
                validationChecks[2][1][0] === 'Invalid'
            ) {
                setConfirm(false);
            }
        } else if(service === ImmediateTCAService || 
            service === MedicalReferralService){
            // When service is ImmediateTCA || MedicalReferral, required fields are mobNo or landNo
            if((validationChecks[1][1][0] === 'Valid' || validationChecks[2][1][0] === 'Valid' ) &&
                validationChecks[5][1] === true){
                setConfirm(true);
            } else if (
                validationChecks[1][1][0] === 'Invalid' ||
                validationChecks[2][1][0] === 'Invalid' ||
                validationChecks[5][1] === false
            ) {
                setConfirm(false);
            }
        } else {
            setConfirm(false);
        }
    }, [validationChecks])

    const [saveSuccess, setSaveSuccess] = useState(false);

    // Function that will be called when confirm button is clicked 
    // and makes POST request to API
    const saveAvivaCase = () => {
        const params = {
            "CaseID": pendingCaseID,
            //"PatientID": patientID,
            "AdditionalNotes":additionalNotes[1],
            "Service": service,
            "ElectronicAddresString":email[1],
            "MobileNo":mobileNo[1],
            "HomeTelNo":homeTelNo[1],
            //"CaseReference": caseReference
        }
        if(confirm){
            setLoading(true);
            api.transact("SaveAvivaCase", params)
                .then(r => r.apiResult)
                .then(r => {
                    setLoading(false);
                    setErr("");
                    setCaseReference(r.CaseReference);
                    setExpiryStatus('Save Success')
                    const urlString = r.NextURL;
                    // const urlString = 'https://www.google.com'
                    const regexPatternHTTPS = /^https?:\/\//i;
                    if(regexPatternHTTPS.test(urlString)){
                        window.location.replace(urlString);
                    } else {
                        setSaveSuccess(true)
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setErr(err.message);
                });
        }
    }

    // Service options list (This list changes when Client ID is AvivaHealthBacktoBetterESSENTIALSClient )
    const serviceOptionList = () => {
        const defaultList = (
            <>
                <option value={DigitalTriageService} >Digital Triage</option>
                <option value={BookedTCAService} >Booked TCA</option>
                <option value={ImmediateTCAService}>Immediate TCA</option>
                <option value={MedicalReferralService}>Med Referral Warm Transfer</option>
                <option value={MedReferralChooseAndBookService} >Med Referral Choose & Book</option>               
            </>
        )

        const specialList = (
            <>
                <option value={DigitalTriageService}>Digital Triage</option>
                <option value={BookedTCAService}>Booked TCA</option>
                <option value={ImmediateTCAService}>Warm Transfer</option>
                <option value={MedReferralChooseAndBookService}>Med Referral Choose & Book</option>  
            </>
        )

        return (
            <>{clientID === AvivaHealthBacktoBetterESSENTIALSClient ? specialList : defaultList}</>
        )
    }

    const formLayout = () => {
        return (
            <>
                {expiryStatus === 'valid' || expiryStatus === '<5mins' || (apiResult[0][1] === 'success' && expiryStatus !== 'expired' ) ? 
                    (<>
                        <div className="typography">
                            <LoadingIndicator loading={loading} />
                            <form name="ReportForm" id="ReportForm" className="form">

                                <fieldset className="form__fieldset form__fieldset--gutter">
                                    <div className="form__group">

                                        <div className="form__field">
                                            <label htmlFor='firstName'>Firstname</label>
                                            <input id="firstName" type="text" 
                                                disabled="disabled" 
                                                value={firstName}/>
                                        </div>

                                        <div className="form__field">
                                            <label>Surname</label>
                                            <input type="text" 
                                                disabled="disabled"
                                                value={surname} />
                                        </div>

                                        <div className="form__field">
                                            <label>Date Of Birth</label>
                                            <div className="date_parts">
                                                Day<input 
                                                        type="text" 
                                                        disabled="disabled"
                                                        value={birthDate.Day} 
                                                    />
                                                Month<input 
                                                        type="text" 
                                                        disabled="disabled" 
                                                        value={birthDate.Month} 
                                                    />
                                                Year<input 
                                                        type="text" 
                                                        disabled="disabled"
                                                        value={birthDate.Year} 
                                                    />
                                            </div>
                                        </div>

                                        <div className="form__field">
                                            <label>Claim Number</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={claimNumber} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Policy Number</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={policyNumber} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Address 1</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled" 
                                                value={address1} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Address 2</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={address2}
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Town</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={town} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>County</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={county} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Postcode</label>
                                            <input 
                                                type="text" 
                                                disabled="disabled"
                                                value={postcode} 
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Email Address{
                                                requiredFieldCheck[0][1] === true ? 
                                                (email[0] === 'Invalid' || email[0] === '' ? '*' : '' ) : 
                                                ''}
                                            </label>
                                            <input type="email" 
                                                autoComplete="off" 
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                value={email ? email[1] : ''}
                                                onChange={validateEmail}
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Mobile Number{
                                                requiredFieldCheck[1][1] === true ? 
                                                (mobileNo[0] === 'Invalid' || mobileNo[0] === '' ? '*' : '') : 
                                                ''}
                                            </label>
                                            <input type="tel" 
                                                id="mobNo"
                                                name="MobileNo" 
                                                autoComplete="off"
                                                pattern="[0][1-9]\d{9}$|^[1-9]\d{9}$"
                                                value={mobileNo[1]}
                                                onInput={validateNumber}
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Landline Number{
                                                requiredFieldCheck[2][1] === true ? 
                                                (homeTelNo[0] === 'Invalid' || homeTelNo[0] === '' ? '*' : '') : 
                                                ''}
                                            </label>
                                            <input type="tel" 
                                                id="landNo"
                                                name="HomeTelNo" 
                                                autoComplete="off"
                                                pattern="[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                                value={homeTelNo[1]}
                                                onInput={validateNumber}
                                            />
                                        </div>

                                        <div className="form__field">
                                            <label>Injury</label>
                                            <select disabled="disabled">
                                                {/* Nullish coalescing operator for injury options.
                                                    This will evaluate to empty <option> if 
                                                    injuryOption state is empty
                                                */}
                                                {injuryOption ?? (<option></option>)}
                                            </select>
                                        </div>

                                        <div className="form__field">
                                            <label>Injury Description</label>
                                            <textarea 
                                                type="text" 
                                                rows="5" 
                                                cols="50" 
                                                disabled="disabled"
                                                value={injuryOverview}
                                            ></textarea>
                                        </div>

                                        <div className="form__field">
                                            <label>Additional Notes</label>
                                            <textarea 
                                                type="text" 
                                                rows="5" 
                                                cols="50"
                                                pattern="[(a-zA-Z)+(\!\@\#\$\%\^\&\*\(\))+]"
                                                value={additionalNotes[1]}
                                                onInput={validateNote}
                                            ></textarea>
                                        </div>

                                        <div className="form__field">
                                            <label>Service{!service ?  '*' : <></>}</label>
                                            <select defaultValue="" onChange={(e) => setService(e.target.value)}>
                                                <option value="" disabled> </option>
                                                {serviceOptionList()}
                                            </select>
                                        </div>

                                        {/* Displays message and checkbox section 
                                            according to changes made by user
                                        */}
                                        <ConfirmCheckBox 
                                            service={service} 
                                            setCheckBox={setCheckBox} 
                                            checkBox={checkBox} 
                                            clientID={clientID}
                                        />
                                        <DefaultMessage validationChecks={validationChecks} confirm={confirm}/>
                                    </div>
                                    
                                    <br />

                                    <input 
                                        type="button" 
                                        className="form__action" 
                                        value="Confirm" 
                                        disabled={loading || !confirm} 
                                        onClick={saveAvivaCase}
                                    />
                                </fieldset>
                            </form>
                            <LoadingIndicator loading={loading} />
                            {err && <p id="warning">{err}</p>}     
                        </div>
                    </>)
                : <></>}
            </>
        )
    }

    // Service text for thank you page which is generated depending on which service
    // user have selected. serviceText state will change when user chooses one of select options
    // on the page.
    const [serviceText, setServiceText] = useState('')
    
    const style = {
        textAlign: 'left',
        marginTop: '1em',
        marginBottom: '1.8em',
        fontSize: '0.95em'
    }

   

    useEffect(()=>{
        if(service === ImmediateTCAService || service === MedicalReferralService){
            setServiceText(
                <>
                    <p style={style}>Thank you for your submission, the member’s IPRS Health Case ID is {caseReference}.</p>
                    <p style={style}>Please warm transfer the member to IPRS Health on <PhoneNumber brandingInfo={brandingInfo} />.</p>
                    <p style={style}>If the member does not wish to be warm transferred over to IPRS Health, please call through to the IPRS Health team to request a call back to the member.</p>                
                </>
            )
        } else if (service === DigitalTriageService){
            setServiceText(
                <>
                    <p style={style}>Thank you for your submission, the member’s IPRS Health Case ID is {caseReference}.</p>
                    <p style={style}>A link to the digital triage will now be sent to the member.</p>
                </>
            )
        } else if (service === MedReferralChooseAndBookService){
            setServiceText(
                <>
                    <p style={style}>Thank you for your submission, the member’s IPRS Health Case ID is {caseReference}.</p>
                    <p style={style}>A link will now be sent to the member for them to search and select a clinic from the IPRS Health Network.</p>
                </>
            )
        }
    }, [service, caseReference])

    

    const thankyouMessage = () => {

        return (
            <>
                {serviceText}
            </>
        )
    }

    return (
        <div>
            <div className="container">
                <div className="main">
                    {/* 
                        Conditionally displays formStatus and formLayout based on state of saveSuccess.
                        The page will be redirected if url given is 
                    */}
                    {saveSuccess ? <></> : formStatus()}
                    {saveSuccess ? thankyouMessage() : formLayout()}
                </div>
            </div>
        </div>
    )
}

export default ReferralForm