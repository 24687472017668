/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, {useState}     
                                                    from 'react';         
import {BsFillPlusCircleFill}                       from 'react-icons/bs';
import {BiMinusCircle}                              from 'react-icons/bi';
import {AiOutlineEdit}                              from 'react-icons/ai';
import {BsFillPeopleFill}                           from 'react-icons/bs';
import {AiOutlineUserAdd}                           from 'react-icons/ai';
import {HiUserRemove}                               from 'react-icons/hi';
import {BiCalendarPlus}                             from 'react-icons/bi';
import {BiHide}                                     from 'react-icons/bi';
import { BiShow }                                   from 'react-icons/bi';
import { MdOutlineExpandMore }                      from 'react-icons/md';
import { MdOutlineExpandLess }                      from 'react-icons/md';
import {MdDeleteForever}                            from 'react-icons/md';
import {GiSaveArrow}                                from 'react-icons/gi';
import { FiSettings }                               from 'react-icons/fi';
import {HiOutlineDocumentReport}                    from 'react-icons/hi';

const buttonWrapper = elem => ({onClick, name, value, title, enabled=true}) => {

    const handleClick = e => {
        e.preventDefault();

        if (enabled && onClick){
            onClick( { target: { name, value }, preventDefault: ()=>{} } );
        }
    }
    
    const elemProps = {
        style: {color: 'var(--colour__primary)'}, size: '1.5rem'
    }
    
    return <button type='image' onClick={handleClick} title={title} 
    style={{border:0, backgroundColor: 'rgba(0,0,0,0)', padding: '5px 0', 
        float: 'left', clear: 'none'}} >
        {React.createElement( elem, elemProps )}
        {title}
    </button>
}

const PlusButton    = buttonWrapper(BsFillPlusCircleFill);
const MinusButton   = buttonWrapper(BiMinusCircle);
const EditButton    = buttonWrapper(AiOutlineEdit);
const PeopleButton  = buttonWrapper(BsFillPeopleFill);
const PersonAdd     = buttonWrapper(AiOutlineUserAdd);
const PersonRemove  = buttonWrapper(HiUserRemove);
const CalendarPlus  = buttonWrapper(BiCalendarPlus);
const Hide          = buttonWrapper(BiHide);
const Show          = buttonWrapper(BiShow);
const More          = buttonWrapper(MdOutlineExpandMore);
const Less          = buttonWrapper(MdOutlineExpandLess);
const Delete        = buttonWrapper(MdDeleteForever);
const Save          = buttonWrapper(GiSaveArrow);
const Settings      = buttonWrapper(FiSettings);
const Report        = buttonWrapper(HiOutlineDocumentReport);

const PlusInput = ({onClick, name, defaultValue=''}) => {
    
    const [value, setValue]                 = useState(defaultValue)
    
    const handleSubmit = e => {
        e.preventDefault()
        
        setValue( defaultValue );
        onClick({target: { name, value }, preventDefault: () => {} })
        
    }
    
    return <form onSubmit={handleSubmit} >
        <input type='text' value={value} onChange={e=>setValue(e.target.value)} 
            size='10' style={{border: '1px solid var(--colour__primary)', 
            borderRadius: '5px', marginRight: '5px', height: '2rem'}} />
        <button type='image' onClick={handleSubmit}
        style={{border:0, backgroundColor: 'white', padding: '5px 0 0 0'}} >
            <BsFillPlusCircleFill style={{color: 'var(--colour__primary)'}} 
                size={'1.5rem'}  />
        </button>
    </form>
}
 
const OKCancel = ({onOK, onCancel, okText = 'OK', cancelText = 'Cancel', 
    name='okcancel', enabled=true} ) => {
    
    if(!onOK)       throw new Error("onOK must be set");
    if(!onCancel)   throw new Error("onCancel must be set");

    const disabled = true === !enabled;
    
    const handleOK = e => {
        e.preventDefault()
        
        if(enabled){
            onOK({preventDefault: ()=>{}, target: { name, value: 'ok'} })
        }
    }
    
    const handleCancel = e => {
        onCancel({preventDefault: ()=>{}, target: { name, value: 'cancel'} })
    }
    
    return (
        <div className='wrapper'>
        <button onClick={handleOK} disabled={disabled} >{okText}</button>
        <Cancel onCancel={handleCancel} cancelText={cancelText} />
        </div>
    )
}
  
const OK = ({onOK, okText = 'OK',  name='okcancel', enabled=true} ) => {
    
    if(!onOK)       throw new Error("onOK must be set");
    
    const handleOK = e => {
        e.preventDefault()
        
        if(enabled){
            onOK({preventDefault: ()=>{}, target: { name, value: 'ok'} })
        }
    }
    
    return (
        <div className='wrapper'>
        <button onClick={handleOK} >{okText}</button>
        </div>
    )
}
  
const Cancel = ({onCancel, cancelText='Cancel', className='cancelLink'}) => {

    const handleCancel = e => {
        e.preventDefault()
        onCancel({preventDefault: ()=>{}, target: { name: 'cancel', value: 'cancel'} })
    }

    return <a href='' className={className} onClick={handleCancel}  >{cancelText}</a>
}

const DismissButton = ({onClick, text = 'Done'}) => {
    
    const handleClick = e => {
        e.preventDefault()
        
        onClick()
    }
    
    return (
        <button onClick={handleClick} >{text}</button>
    )
}

/* Component to show a more or less button that toggles on click. Clicking 
 calls onClick with name, value where value is either false or true*/
const MoreLess = ({ onClick, name='moreless', value = false }) => {

    const Button = value ? Less : More;

    if (!onClick) {
        throw new Error('Onclick empty');
    }

    return <Button onClick={onClick} name={name}
        value={!value} Title={value ? 'Click to hide' : 'Click to show'}
        enabled={true}
     />
}


export          {PlusButton, PlusInput, MinusButton, EditButton, PeopleButton, OK, OKCancel, 
                 DismissButton, PersonAdd, PersonRemove, CalendarPlus, Cancel,
                Hide, Show, More, Less, MoreLess, Delete, Save, Settings, Report}
export default   PlusButton



