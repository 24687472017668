
const onRegex       = new RegExp('^\\s*(on|true|yes|1)\\s*$',           'i');
const offRegex      = new RegExp('^\\s*(off|false|no|0)\\s*$',          'i');
const validRegex    = new RegExp('^\\s*([A-Za-z][A-Za-z0-9-]*)\\s*$',   'i');

/** Function to default autocomplete to 'off' but allow 
 * standard values which a generally word characters with dashes.
 * Also treat 0, false, no to be off and yes, true etc to be 'on'
 * see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
const fixAutocomplete = autocompleteVal => {
    let result;
    if (true === autocompleteVal || 1 === autocompleteVal) {
        result = 'on';
    } else if (false === autocompleteVal || 0 === autocompleteVal) {
        result = 'off';
    } else if (typeof autocompleteVal === 'string') {
        if (onRegex.test(autocompleteVal)) {
            result = 'on';
        } else if (offRegex.test(autocompleteVal)) {
            result = 'off';
        } else {
            result = validRegex.test(autocompleteVal) ? autocompleteVal.trim() : 'off';
        }
    } else {
        result = 'off';
    }
    return result;
}

export default fixAutocomplete;