import React from 'react'

const OptionFooter = () => {
  return (
    <footer className="footer">
        <div className="container">
            <div className="footer__inner">
                <a href="https://www.iprshealth.com/cookie-policy/" title="Privacy Policy">Privacy & Cookies</a>
                <p>&copy; 2019-2023 IPRS Health Limited, Speed Medical House, Chorley, Lancashire PR7 1NA. Registered in England and Wales (03015659).</p>
            </div>
        </div>
    </footer>
  )
}

export default OptionFooter